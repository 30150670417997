import { render, staticRenderFns } from "./AddCarParts.vue?vue&type=template&id=44fff128&scoped=true&"
import script from "./AddCarParts.vue?vue&type=script&lang=js&"
export * from "./AddCarParts.vue?vue&type=script&lang=js&"
import style0 from "./AddCarParts.vue?vue&type=style&index=0&id=44fff128&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44fff128",
  null
  
)

export default component.exports