<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Enter Part Details"
      centered
      no-close-on-backdrop
      hide-footer
      @show="showModal"
      @hidden="resetModal"
      @hide="resetModal"
    >
      <form ref="form" @submit.stop.prevent="save">
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group
              id="input-group-2"
              label="Interchange"
              label-for="input-2"
            >
              <b-form-select
                :disabled="interchangeList.length == 0"
                id="input-2"
                type="text"
                class="form-control"
                placeholder="Part Grade"
                v-model="data.interchange"
                @change="onChangeInterchange($event)"
                :class="(!data.interchange && isInterchangeRequired) ? 'error-message' : ''"
              >
                <!-- <option :value="null" :key="0">{{data.select_interchange_text}}</option> -->
                <option :value="null" v-if="interchangeList.length == 0">{{ $t("partDialog.select_no_interchange_found") }}</option>
                <option :value="null" v-if="interchangeList.length > 0">{{ $t("partDialog.select_interchange") }}</option>
                <template v-for="(interchange, index) in interchangeList">
                  <template>
                    <option :v-if="interchange.IntchNbr" v-bind:class="[interchange.IntchNbr ? 'font-weight-bold mb-3' : '']" :key="index+50" :value="interchange.InterchangeNumber" :disabled="!interchange.IntchNbr">{{ interchange.Application }}&nbsp;&nbsp;&nbsp;&nbsp;{{ interchange.InterchangeNumber ? '(' + interchange.InterchangeNumber +')': '' }}
                  </option>
                  </template>
                </template>
              </b-form-select>
            </b-form-group>
            <span class="errMsg" v-if="isInterchangeRequired && !data.interchange">{{ $t("validations.interchangeIsRequired") }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <div><span id="msg"></span></div>
            <div class="upload-images-wrapper row">
              <span class="col-md-12"><b>Part Image</b></span>
              <div class="col-md-12"> (<span class="errMsg" style="position: unset;">{{ $t('partsList.partImageNote') }}</span>)</div>
              <div class="upload-images col-md-6">
                <b-form-group id="input-group-01" label-for="input-01">
                  <image-uploader
                    ref="img1"
                    id="fileInput"
                    :quality="0.9"
                    outputFormat="verbose"
                    :preview=false
                    :className="['hidden']"
                    @input="onFileChange($event, 'img1')"
                    >
                    <label for="fileInput" slot="upload-label" class="node-image-uploader">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <path
                            class="path1"
                            d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                          ></path>
                        </svg>
                      </figure>
                      <span class="upload-caption" style="padding-left: 10px;padding-bottom: 10px;">Click to upload</span>
                    </label>
                  </image-uploader>
                </b-form-group>
                  <!-- {{
                    !(this.$route.params.uid && this.image['img1'])
                      ? $t("validations.required")
                      : ""
                  }} -->
                <div id="img1" class="select-image"></div>
                <a
                  id="img1-delIcon"
                  v-if="this.image.length > 0"
                  @click="deleteImage('img1')"
                  class="delicon"
                  data-tooltip="Delete"
                >
                  <!-- <img src="../assets/delete.svg" height="30"/> -->
                </a>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group
              id="input-group-7"
              :label="$t('partsList.recommendedSales$')"
              label-for="input-7"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">$</span>
                </b-input-group-prepend>
                <b-form-input
                  id="input-7"
                  type="number"
                  placeholder="Recommended Sales $"
                  v-model="data.recommended_sale_price"
                  @input=""
                  @keypress="validCost($event)"
                >
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group
              id="input-group-8"
              label="Part Grade"
              label-for="input-8"
            >
              <b-form-select
                id="input-8"
                type="text"
                class="form-control"
                :options="gradeOptions"
                placeholder="Part Grade"
                v-model="data.part_grade"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group
              id="input-group-10"
              label="Warranty"
              label-for="input-10"
            >
              <b-form-select
                id="input-10"
                type="number"
                class="form-control"
                :options="warrantyOptions"
                v-model="data.warranty_days"
                placeholder="Warranty"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group
              id="input-group-10"
              label="Description"
              label-for="input-10"
            >
              <b-form-textarea
                id="textarea"
                placeholder="Enter something..."
                rows="3"
                v-model="data.description"
                @input="$v.data.description.$touch()"
                @blur="$v.data.description.$touch()"
                :class="
                      this.$v.data.description.$dirty &&
                      $v.data.description.$invalid
                        ? 'error-message'
                        : ''
                    "
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <span class="errMsg"> {{ descriptionErrors[0] }}</span>
          </b-col>
        </b-row>
      </form>
      <div class="d-block text-center">
        <b-button class="mt-4 btn custom-btn custom-danger"
                  variant="outline-danger"
                  @click="resetModal">
          {{ $t("partDialog.cancel") }}
        </b-button>

        <b-button
          class="ml-2 mt-4 btn custom-btn pattern-btn"
          @click="handleOk"
        >
          <span>{{ $t("partDialog.save") }}</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {between, maxLength, required, requiredIf} from "vuelidate/lib/validators";
import {showFailure, showSuccess} from "@/eventbus/action";
import $ from "jquery";
import EventBus from "@/eventbus";

export default {
  props: {
    partId: {
      type: String,
      required
    }, partNumber: {
      type: String,
      required
    }, partName: {
      type: String,
      required
    }, type: {
      type: String,
      default: 'PartType'
    }, dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      image: {},
      inputs: [
        {
          file: "",
        },
      ],
      files_url: "",
      selected: [],
      data: {
        partId: null,
        interchange: null,
        media_ids: null,
        part_name: null,
        part_grade: null,
        quantity: 0,
        warranty_days: null,
        part_location: null,
        yard_location: null,
        description: null,
        recommended_sale_price: "",
        select_interchange_text: '',
      },
      interchangeOptions:[
        {text: "Select", value: null},
      ],
      gradeOptions: [
        {text: "Select", value: null},
        {text: "A", value: "A"},
        {text: "B", value: "B"},
        {text: "C", value: "C"},
      ],
      warrantyOptions: [
        {text: "Select", value: null},
        {text: "AS IS", value: "AS IS"},
        {text: "5 days", value: "5 days"},
        {text: "15 days", value: "15 days"},
        {text: "30 days", value: "30 days"},
        {text: "60 days", value: "60 days"},
        {text: "90 days", value: "90 days"},
      ],
      isInterchangeRequired: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      part: "partlist/getPart",
      // imageResponse: "partlist/getImageResponse",
      mediaImageResponse: "partlist/getMediaImageResponse",
      interchangeList: "partlist/getInterchange",
      carDetail: "carlist/getCarDetail",
    }),
    descriptionErrors() {
      const errors = [];
      if (!this.$v.data.description.$dirty) return errors;
      !this.$v.data.description.maxLength &&
      errors.push(this.$t("validations.desMaxLength"));
      return errors;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
    partId(val) {
      if (val) {
        this.getPartById();
      }
    }
  },
  validations: {
    data: {
      part_name: {required},
      description: {maxLength: maxLength(150)},
    },
    imageResponseArray: [],
  },
  mounted() {
    if (this.$route.params.vid) {
      this.getCarDetail(Number(this.$route.params.vid));
    }
  },
  updated(){
    if(this.interchangeList.length < 1){
      this.data.select_interchange_text = this.$t("partDialog.select_no_interchange_found");
      this.data.interchange = null;
    }else{
      this.data.select_interchange_text = this.$t("partDialog.select_interchange");
    }
  },
  methods: {
    ...mapActions({
      // uploadImage: "partlist/uploadImage",
      uploadMediaImage: "partlist/uploadMediaImage",
      addAuctionParts: "partlist/addAuctionParts",
      partsById: "partlist/getPartsById",
      getInterchange: "partlist/getInterchange",
      updateVehicleParts: "partlist/updateVehicleParts",
      addDirectParts: "partlist/addDirectParts",
      getPart: "partlist/getPart",
      getCarDetail: "carlist/getCarDetail",
    }),

    async getPartById() {
      try {
        this.$store.commit("setLoader", true);
        let carProfileId = this.$route.params.vid ? this.$route.params.vid : this.$route.params.uid;

        await this.getPart({
          id: this.partId,
          type: this.type,
          carProfileId: carProfileId,
          partName: this.partName,
        });

        await this.getInterchange({
          BeginYear: this.carDetail.year,
          MfrCd: this.carDetail.make_code,
          ModelNm: this.carDetail.model,
          PartType: this.partNumber
        });

        if(this.part.car_part){
          if (this.part.car_part.medias.length > 0 && this.part.car_part.medias[0].url100) {
            $(".select-image").empty();
            $(".select-image").append("<img class='img1' src='' alt=''>");
            $(".img1").attr("src", this.part.car_part.medias[0].url100);
          }
          this.data.media_ids = [this.part.car_part.medias].map(
            (val) => val.id
          );
          this.data.partId = this.partId;
          this.data.part_name = this.part.car_part.part_name;
          this.data.part_grade = this.part.car_part.part_grade;
          this.data.quantity = this.part.car_part.quantity;
          this.data.warranty_days = this.part.car_part.warranty_days;
          this.data.part_location = this.part.car_part.part_location;
          this.data.yard_location = this.part.car_part.yard_location;
          this.data.description = this.part.car_part.description;
          this.data.interchange = this.part.car_part.interchange_code ?? null;
          this.data.recommended_sale_price = this.part.car_part.recommended_sale_price;
        }
      } catch ({message}) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    validateForm() {
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        return true;
      } else {
        return false;
      }
    },

    convertUSCurrency() {
      let currency = 0;
      if(this.data.recommended_sale_price){
        currency = Number(
          this.data.recommended_sale_price.toString().replace(/[^0-9/.]/g, "")
        )
          .toString()
          .split(".");
      }

      if (!currency[1]) {
        this.data.recommended_sale_price = currency[0]
          .replace(/\D/g, "")
          .split("")
          .reverse()
          .reduce(function (acc, num, i) {
            return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
          }, "");
      } else {
        this.data.recommended_sale_price =
          currency[0]
            .replace(/\D/g, "")
            .split("")
            .reverse()
            .reduce(function (acc, num, i) {
              return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") +
          "." +
          currency[1];
      }
    },

    validCost(e) {
      const validate = this.data.recommended_sale_price.split(".");
      if (validate[1] && validate[1].length >= 2) {
        e.preventDefault();
      }
      const reg = /[^0-9]/;
      if (reg.test(e.key)) {
        e.preventDefault();
      }
    },

    showModal() {
      this.$refs["modal"].show();
    },
    hideModal() {
      this.resetData();
      EventBus.$emit("resetDialog");
      this.$refs["modal"].hide();
    },

    resetModal() {
      this.hideModal();
    },

    async saveImage(file, tag) {
      try {
        this.$store.commit("setLoader", true);
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", this.user.user_detail.company_name);
        // await this.uploadImage(formData);
        await this.uploadMediaImage(formData);
        showSuccess("Image Saved Successfully");
        // return this.imageResponse;
        return this.mediaImageResponse;
        // this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({message}) {
        this.$refs[tag].$el.children[1].value = "";
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    deleteImage(type) {
      this.$refs[type].$el.children[1].value = "";
      $(`#${type}`).empty();
      $(`#${type}-delIcon`).hide();
      delete this.image[type];
      // delete this.image['social'];
    },

    async onFileChange(fileData, type) {
      $(`#${type}`).empty();
      $(`#${type}`).append(`<img class=${type} src='' alt=''>`);
      var selectedImage = await this.saveImage(fileData.dataUrl, type);
      this.image[type] = selectedImage.data;
      $(`.${type}`).attr("src", selectedImage.data.url100);
      $(`#${type}-delIcon`).show();
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.save()
    },

    onChangeInterchange(newInterchange) {
      if(this.interchangeList && this.interchangeList.length != 0) {
        this.isInterchangeRequired = true;
        // this.data.interchange = newInterchange;
      } else {
        this.isInterchangeRequired = false;
      }
    },

    async save() {
      this.data.part_name = this.part.name
      this.data.part_number = this.part.id
      if (!this.validateForm()) {
        console.log('invalid form...');
      }
      this.imageResponseArray = [];
      Object.keys(this.image).forEach((val) => {
        this.imageResponseArray.push(this.image[val]);
      });
      this.$v.data.$touch();
      this.onChangeInterchange();
      if (!this.$v.data.$invalid && ((this.isInterchangeRequired == true && typeof this.data.interchange === 'string') || this.isInterchangeRequired == false)) {
        try {
          this.$store.commit("setLoader", true);
          let payload = Object.assign({}, this.data);
          payload.id = this.data.partId;
          payload.car_profile_id = this.$route.params.vid;
          payload.interchange_code = this.data.interchange;
          payload.is_on_facebook = this.selected.some(
            (val) => val === "is_on_facebook"
          );
          payload.is_on_ebay = this.selected.some(
            (val) => val === "is_on_ebay"
          );
          payload.is_on_instagram = this.selected.some(
            (val) => val === "is_on_instagram"
          );

          payload.media_ids = this.imageResponseArray.map((val) => val.id);
          if(this.imageResponseArray.length <= 0){
            if(this.part.car_part && this.part.car_part.medias && this.part.car_part.medias.length > 0) {
              this.data.media_ids = [this.part.car_part.medias[0].id];
              payload.media_ids = [this.part.car_part.medias[0].id];
            }
          }

          if (this.$route.params.vid) {
            await this.addAuctionParts({
              ...payload,
            }).then(()=>EventBus.$emit("getParts"));

            if(this.part.car_part){
              showSuccess("Part Updated Successfully");
            }else {
              showSuccess("Part Added Successfully");
            }
          }

          this.resetData();
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })

          this.hideModal();

        } catch ({message}) {
          console.log('Error : ', message);
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showFailure(errorMessage);
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },

    resetData(){
      this.data = {
        interchange: null,
        media_ids: null,
        part_name: null,
        part_grade: null,
        quantity: null,
        warranty_days: null,
        part_location: null,
        yard_location: null,
        description: null,
        recommended_sale_price: "",
        select_interchange_text: '',
      };
      this.image = {};
      this.inputs= [
        {
          file: "",
        },
      ];
      this.files_url= "";
      this.selected= [];
      $(".select-image").empty();
    }

  }
}
</script>
<style>
.hidden { 
  display: none;
}
</style>
